import React, { useState, useEffect, useReducer, useContext } from "react";
import { Button, Col, Row } from "../../components";
import TextField from "@material-ui/core/TextField";
import Content from "../../layouts/content";
import { getCompaniesList } from "../../redux/actions/memberAction";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Nav from "../../layouts/nav/Nav";
import { StyledHeader, StyledContainer, useStylesButton, useStylesHeader, useStyles, useStylesDataGrid } from "./style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SERVICEURL, v1link } from "../../appconfig";
import axios, { AxiosError } from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Autocomplete from "@mui/material/Autocomplete";
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "../../context/ThemeContextProvider";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { List } from "@material-ui/icons";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { hashID } from "../../utils/hash";


const today = new Date();
const oneYearAgo = new Date(
  today.getFullYear() - 1,
  today.getMonth(),
  today.getDate()
);

const JobResignationReport: React.FC = () => {
  const { darkModeActive } = useContext(ThemeContext);
  const dis = useDispatch();

  type State = {
    name: string;
    dateStart: Date | null;
    dateEnd: Date | null;
  };
  const initialState: State = {
    name: "",
    dateStart: oneYearAgo,
    dateEnd: today,
  };
  type serviceResponse = {
    id: number;
    name: string;
  };

  type Action =
    | { type: "setName"; payload: string }
    | { type: "setDateStart"; payload: Date | null }
    | { type: "setDateEnd"; payload: Date | null };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "setName":
        return {
          ...state,
          name: action.payload,
        };
      case "setDateStart":
        return {
          ...state,
          dateStart: action.payload,
        };
      case "setDateEnd":
        return {
          ...state,
          dateEnd: action.payload,
        };
    }
  };
  interface EvaluationFormListDTO {
    Id: Number;
    EvaluationFormID: Number;
    CompanyID: Number;
    CompanyName: String;
    CustomerID: Number;
    CustomerName: String;
    MemberID: Number;
    MemberName: String;
    FormTypeID: Number;
    FormTypeName: String;
    StatusID: Number;
    StatusName: String;
    FormDate: Date;
  }

  const [evaluationForms, setEvaluationForms] = useState<
    EvaluationFormListDTO[]
  >([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [customers, setCustomers] = useState<serviceResponse[]>([]);
  const [customerValue, setcustomerValue] = useState<any | null>();
  const [customerOptionName, setcustomerOptionName] = useState("");
  const [companyValue, setCompanyValue] = useState<any | null>();
  const [isCompanyError, setIsCompanyError] = useState(false);
  const [isFirstLogin,setIsFirstLogin] = useState(true);
  
  const mytoken = localStorage.getItem("token");
  const HEADER = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + mytoken,
    },
  };

  const { companiesList } = useSelector(
    (state: RootStateOrAny) => state.companies
  );

  const getCompanies = () => {
    dis(getCompaniesList());
  }

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const input = { Id: companyValue };
    axios
      .post(SERVICEURL + "api/Data/GetCustomerListbyCompanyId", input, HEADER)
      .then((response) => {
        if (
          response.data.CustomerList != undefined &&
          response.data.CustomerList != null
        ) {
          setCustomers(response.data.CustomerList);
          setcustomerValue(null);
          setcustomerOptionName("");
        } else {
          setCustomers([]);
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response!.status === 401) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          console.log("Handle else:", reason.message);
        }
      });
  }, [companyValue]);

  const getList = () => {
    let hasError = false;

    const fields = [
      { value: companyValue, setter: setIsCompanyError },
      // { value: customerValue, setter: setIsCustomerError },
      // { value: employeeValue, setter: setIsEmployeeError },
      // { value: formValue, setter: setIsFormError },
    ];

    fields.forEach((field) => {
      const isError = field.value == 0 || field.value == null;
      field.setter(isError);
      if (isError) hasError = true;
    });

    setOpenAlert(hasError);
    if (hasError) return;

    const input = {
      CompanyID: companyValue,
      CustomerID: customerValue?.id,
      FormTypeID: 4,
      StartDate: state.dateStart,
      EndDate: state.dateEnd,
    };

    axios
      .post(SERVICEURL + "api/Data/ListEvaluationForm", input, HEADER)
      .then((response) => {
        setIsFirstLogin(false);
        setEvaluationForms(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const handleChangeCompany = (event: any, newValue: any | null) => {
    if (newValue != null && newValue != undefined) {
      setCompanyValue(newValue.id);
    }
    if (newValue == null) {
      setCompanyValue(newValue);
    }
  };

  const handleChangeCustomer = (event: any, newValue: any) => {
    if (newValue != null) {
      setcustomerValue(newValue);
    }
    if (newValue == null) {
      setcustomerValue(0);
    }
  };

  const handleStartDate = (newValue: Date | null) => {
    dispatch({
      type: "setDateStart",
      payload: newValue,
    });
  };

  const handleEndDate = (newValue: Date | null) => {
    dispatch({
      type: "setDateEnd",
      payload: newValue,
    });
  };

  const classesHeader = useStylesHeader();
  const classesButton = useStylesButton();
  const classesScroll = useStyles();
  const classesDataGrid = useStylesDataGrid();

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseAlert = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <>
      <Helmet>
        <title>RDC Board ~ İşten Çıkış Değerlendirme Rapor</title>
      </Helmet>
      <div
        className={classesScroll.scroll}
        style={{
          backgroundColor: darkModeActive ? "rgb(20, 28, 43)" : "#f7faff",
        }}
      >
        <Nav />
        <Content>
          <StyledHeader
            className={classesHeader.header}
            darkModeActive={darkModeActive}
          >
            İşten Çıkış Değerlendirme Rapor
          </StyledHeader>
          <StyledContainer
            className={classesButton.TextFieldControl}
            darkModeActive={darkModeActive}
          >
            <Row>
              <Col lg md sm xs mb={25}>
                {Object.values(companiesList).length > 0 && (
                  <Autocomplete
                    autoHighlight
                    options={Object.values(companiesList.data.Companies).map(
                      (companies: any) => companies
                    )}
                    getOptionLabel={(options) => options.name}
                    onChange={handleChangeCompany}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Şirket *"
                        error={isCompanyError}
                      />
                    )}
                    filterOptions={(options, { inputValue }) => {
                      return options.filter((option) =>
                        option.name
                          .toLocaleLowerCase("tr-TR")
                          .includes(inputValue.toLocaleLowerCase("tr-TR"))
                      );
                    }}
                  />
                )}
              </Col>
              <Col lg md sm xs mb={25}>
                <Autocomplete
                  key={companyValue}
                  options={customers}
                  getOptionLabel={(options) =>
                    options.name == undefined
                      ? customerOptionName
                      : options.name
                  }
                  onChange={handleChangeCustomer}
                  value={customerValue == null ? 0 : customerValue}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      label="Müşteri"
                      // error={isCustomerError}
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter(
                      (option) =>
                        option.name &&
                        option.name
                          .toLocaleLowerCase("tr-TR")
                          .includes(inputValue.toLocaleLowerCase("tr-TR"))
                    );
                  }}
                />
              </Col>
              <Col lg={2} md sm xs mb={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Başlangıç Tarihi *"
                    value={state.dateStart}
                    onChange={(newValue: Date | null) => {
                      newValue?.setHours(12);
                      const formattedDate = newValue
                        ? newValue.toLocaleDateString("tr-TR")
                        : "";
                      if (formattedDate.length === 10) {
                        if (
                          !state.dateEnd ||
                          (newValue && newValue <= state.dateEnd)
                        ) {
                          handleStartDate(newValue);
                        } else {
                          alert(
                            "Başlangıç tarihi bitiş tarihinden büyük olamaz!"
                          );
                          handleStartDate(null);
                        }
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Col>
              <Col lg={2} md sm xs mb={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Bitiş Tarihi *"
                    value={state.dateEnd}
                    onChange={(newValue: Date | null) => {
                      newValue?.setHours(12);
                      const formattedDate = newValue
                        ? newValue.toLocaleDateString("tr-TR")
                        : "";
                      if (formattedDate.length === 10) {
                        if (
                          !state.dateStart ||
                          (newValue && newValue >= state.dateStart)
                        ) {
                          handleEndDate(newValue);
                        } else {
                          alert(
                            "Bitiş tarihi başlangıç tarihinden küçük olamaz!"
                          );
                          handleEndDate(null);
                        }
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  iconButton
                  size="lg"
                  color="light"
                  className={classesButton.buttonList}
                  onClick={getList}
                >
                  <List
                    width={18}
                    height={18}
                    className={classesButton.buttonIcon}
                  />
                  LİSTELE
                </Button>
              </Col>
            </Row>
            <br /> <br />
            {isFirstLogin ? null : evaluationForms.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  fontSize: "16px",
                  color: "gray",
                }}
              >
                Girilen bilgilerle ilgili bir kayıt bulunamadı.
              </div>
            ) : (
              <DataGrid
                sx={{
                  backgroundColor: darkModeActive ? "#3a475f" : "white",
                  "& .p": {
                    color: darkModeActive ? "rgb(119, 128, 145)" : "black",
                  },
                }}
                autoHeight={true}
                rows={evaluationForms.map((item: any, index: any) => ({
                  id: index + 1,
                  EvaluationFormID: hashID(item.EvaluationFormID),
                  CompanyID: item.CompanyID,
                  CompanyName: item.CompanyName,
                  CustomerID: item.CustomerID,
                  CustomerName: item.CustomerName,
                  MemberID: item.MemberID,
                  MemberName: item.MemberName,
                  StatusID: item.StatusID,
                  StatusName: item.StatusName,
                  FormDate: item.FormDate,
                  link: item.RdcLink,
                }))}
                columns={[
                  { field: "id", headerName: "", flex: 0.01 },
                  { field: "CompanyName", headerName: "Şirket", flex: 0.8 },
                  { field: "CustomerName", headerName: "Müşteri", flex: 1 },
                  {
                    field: "MemberName",
                    headerName: "Personel",
                    flex: 1.2,
                    renderCell: (params: any) => [
                      <a
                        href={v1link + `/employee/detail/${params.row.link}`}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        {params.value}
                      </a>,
                    ],
                  },
                  {
                    field: "StatusName",
                    headerName: "Durum",
                    flex: 0.6,
                    renderCell: (params: any) =>
                      params.row.EvaluationFormID === 0 ? (
                        <span style={{ color: "red" }}>Gönderilmedi</span>
                      ) : (
                        params.value
                      ),
                  },
                  { field: "FormDate", headerName: "Tarih", flex: 0.6 },
                  {
                    field: "actions",
                    type: "actions",
                    width: 100,
                    getActions: (params: any) =>
                      params.row.StatusID === 1
                        ? []
                        : [
                            <GridActionsCellItem
                              icon={<VisibilityIcon />}
                              label="View"
                              component={Link}
                              target="_blank"
                              to={`/evaluationFormDetail/jobResignationForm/${params.row.EvaluationFormID}`}
                            />,
                          ],
                  },
                ]}
                getRowClassName={(params) =>
                  params.row.EvaluationFormID === 0
                    ? classesDataGrid.rowError
                    : params.row.StatusID === 2
                    ? classesDataGrid.rowSuccess
                    : ""
                }
              />
            )}
            <Row>
              <Col>
                <Snackbar
                  open={open}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={() => handleClose}
                    severity="success"
                    sx={{ width: "200%" }}
                  >
                    Başarıyla kaydedildi.
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={handleCloseAlert}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <Alert
                    onClose={() => handleCloseAlert}
                    severity="error"
                    sx={{ width: "200%" }}
                  >
                    Zorunlu Alanları Boş Bıraktınız !!!
                  </Alert>
                </Snackbar>
              </Col>
            </Row>
          </StyledContainer>
        </Content>
      </div>
    </>
  );
};
export default JobResignationReport;
